<form [formGroup]="form" (ngSubmit)="submit()">
    <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
    <div class="row mt-5 external-login-providers">
        <div class="col-6 offset-3">
            <button mat-raised-button (click)="login()" type="button">
                <img src="/assets/images/whmcs.png" alt="">
            </button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-8 text-muted">
            <small>© 2021–{{this.getCurrentYear()}}</small>
        </div>
    </div>
</form>
