import { Component } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

    constructor(
        public readonly authService: AuthService,
        public readonly dialog: MatDialog,
    ) { }
}
