<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="menu" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
    </button>
    <span>SIMP</span>
    <span class="toolbar-spacer"></span>
    <button mat-icon-button aria-label="Logout of the application" id="profile-button" routerLink="/profile">
        <img [src]="authService.getCurrentUserAvatar()" alt="">
    </button>
    <button mat-icon-button  aria-label="Logout of the application" (click)="logoutHandle()">
        <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav mode="side" [opened]="menuIsOpen" #sidenav>
        <mat-list role="list" class="menu">
            <mat-list-item [routerLinkActive]="['active']" [routerLink]="['/dashboard']">
                <mat-icon>home</mat-icon>
                <div mat-line>Dashboard</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item role="listitem" [routerLinkActive]="['active']" [routerLink]="['/servers']">
                <div mat-line>Servers</div>
                <mat-icon>storage</mat-icon>
            </mat-list-item>
            <mat-divider></mat-divider>
        </mat-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
