import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Server} from '../../../../../models/server';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JsonApiErrorResponse} from "../../../../../services/json-api-error-response";
import {HttpClient} from "@angular/common/http";
import {Datastore} from "../../../../../services/datastore";

@Component({
    selector: 'app-confirm-server-unsuspend-dialog',
    templateUrl: './confirm-server-unsuspend-dialog.component.html',
    styleUrls: ['./confirm-server-unsuspend-dialog.component.scss']
})
export class ConfirmServerUnsuspendDialogComponent {

    public form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfirmServerUnsuspendDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public server: Server,
        private readonly formBuilder: FormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
    ) {
        this.form = this.formBuilder.group({

        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.http.patch('/api/v1/servers/' + this.server.id + '/unpause', {}, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            }
        }).subscribe(() => {
            this.snackbar.open('The server will be unsuspended shortly');
            this.dialogRef.close();
        }, (error: JsonApiErrorResponse) => this.datastore.showError(error));
    }
}
