import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class VersionMismatchInterceptor implements HttpInterceptor {

    public constructor(private snackBar: MatSnackBar) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(res => {
                if (res instanceof HttpResponse && res.headers.get('Version')) {
                    if (environment.version !== res.headers.get('Version')) {
                        // Change what happens on a version mismatch here.
                        const snackbar = this.snackBar.open('There is a new version please reload', 'Reload now');
                        snackbar.onAction().subscribe(() => {
                            window.location.reload();
                        });
                    }
                }
            }),
        );
    }
}
