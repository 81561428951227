<div class="container">
    <button mat-raised-button color="primary" class="mat-elevation-z8 add-button" (click)="showAddServerDialog()">
        <mat-icon>add</mat-icon>
        Add Server
    </button>

</div>

<div class="list mt-3">
    <div class="w-100">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100" matSort
               [matSortActive]="pageInfo.sort"
               [matSortDirection]="pageInfo.direction"
               (matSortChange)="announceSortChange($event)">
            <caption style="display: none">Servers</caption>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">
                    #
                </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
            </ng-container>

            <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef> Purpose</th>
                <td mat-cell *matCellDef="let element"> {{element.purpose}} </td>
            </ng-container>

            <ng-container matColumnDef="server_type">
                <th mat-header-cell *matHeaderCellDef> Type</th>
                <td mat-cell *matCellDef="let element"> {{element.provider | serverType}} </td>
            </ng-container>

            <ng-container matColumnDef="datacenter">
                <th mat-header-cell *matHeaderCellDef> Datacenter</th>
                <td mat-cell *matCellDef="let element"> {{element?.datacenter?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="assignee_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by assignee">
                    Assigned
                </th>
                <td mat-cell *matCellDef="let element"> {{element.assignee ? 'Yes' : 'No'}} </td>
            </ng-container>

            <ng-container matColumnDef="contractExpireAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by contract date">
                    Contract end
                    date
                </th>
                <td mat-cell *matCellDef="let element"> {{(element.contractExpireAt | date) ?? '-'}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element"> {{element.status | titlecase}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetails($event, row)" (auxclick)="goToDetails($event, row)" class="server-row"></tr>
        </table>

        <mat-paginator [length]="this.pageInfo.total"
                       [pageIndex]="this.pageInfo.number"
                       [pageSize]="this.pageInfo.size"
                       [pageSizeOptions]="[10, 25, 50, 100]"
                       showFirstLastButtons
                       (page)="updatePage($event)"
                       aria-label="Select page"
                       class="mt-2 mat-elevation-z8"
        ></mat-paginator>
    </div>
    <div class="filter-container px-3 py-2 bg-white h-auto">
        <mat-form-field appearance="legacy">
            <mat-label>Name</mat-label>
            <input matInput [value]="filters.name || ''" (input)="applyInputFilter('name', $event)"/>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>IPv4/IPv6 address</mat-label>
            <input matInput [value]="filters.networks.address || ''" (input)="applyInputFilter('networks.address', $event)" />
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Assigned</mat-label>
            <mat-select [value]="filters.assigned" (selectionChange)="applyFilterAssigned($event)">
                <mat-option>All</mat-option>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Select a purpose</mat-label>
            <mat-select multiple [value]="filters.purpose" (selectionChange)="applyFilterPurpose($event)">
                <mat-option value="customer">customer</mat-option>
                <mat-option value="appslot">appslot</mat-option>
                <mat-option value="seedbox">seedbox</mat-option>
                <mat-option value="miscellaneous">miscellaneous</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Select a provider</mat-label>
            <mat-select [value]="filters.provider" (selectionChange)="applyFilterProvider($event)">
                <mat-option>All</mat-option>
                <mat-option value="Hetzner">Hetzner</mat-option>
                <mat-option value="Leaseweb">Leaseweb</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Provider ID</mat-label>
            <input matInput [value]="filters.provider_id || ''" (input)="applyInputFilter('provider_id', $event)"/>
        </mat-form-field>
    </div>
</div>
