<div class="row">
    <div class="col-md-3">
        <mat-card class="mt-4">
            <mat-card-title>Newest servers</mat-card-title>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item *ngFor="let server of newestServers" role="listitem" [routerLink]="['/servers', server.id]" class="pointer">
                        <h3 matLine> {{server.productType}} </h3>
                        <p matLine> {{server?.provider | serverType | titlecase}} </p>
                        <p matLine> {{server.createdAt | date:'medium'}} </p>
                        <mat-icon matListIcon>storage</mat-icon>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-3">
        <mat-card class="mt-4">
            <mat-card-title>Updated servers</mat-card-title>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item *ngFor="let server of updatedServers" role="listitem" [routerLink]="['/servers', server.id]" class="pointer">
                        <h3 matLine> {{server.productType}} </h3>
                        <p matLine> {{server?.provider | serverType | titlecase}} </p>
                        <p matLine> {{server.updatedAt | date:'medium'}} </p>
                        <mat-icon matListIcon>storage</mat-icon>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-3">
        <mat-card class="mt-4">
            <mat-card-title>Contract due servers</mat-card-title>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item *ngFor="let server of expiringServers" role="listitem" [routerLink]="['/servers', server.id]" class="pointer">
                        <h3 matLine> {{server.productType}} </h3>
                        <p matLine> {{server?.provider | serverType | titlecase}} </p>
                        <p matLine> {{server.contractExpireAt | date:'medium'}} </p>
                        <mat-icon matListIcon>storage</mat-icon>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-3">
        <mat-card class="mt-4">
            <mat-card-title>Pending servers</mat-card-title>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item *ngFor="let server of pendingServers" role="listitem" [routerLink]="['/servers', server.id]" class="pointer">
                        <h3 matLine> {{server.productType}} </h3>
                        <p matLine> {{server?.provider | serverType | titlecase}} </p>
                        <p matLine> {{server.createdAt | date:'medium'}} </p>
                        <mat-icon matListIcon>storage</mat-icon>
                    </mat-list-item>
                    <mat-list-item *ngIf="!pendingServers.length" role="listitem" class="pointer">
                        <p matLine>No pending servers.</p>
                        <mat-icon matListIcon>done</mat-icon>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>
