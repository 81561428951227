<h1 mat-dialog-title>Select date</h1>
<div mat-dialog-content class="mat-dialog-content">
    <mat-form-field>
        <mat-label>Choose a from date</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="data.from">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Choose a to date</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="data.to">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary">Select</button>
</div>
