import {Attribute, JsonApiModel, JsonApiModelConfig} from 'mpugach-angular2-jsonapi';

@JsonApiModelConfig({
  type: 'permissions'
})
export class Permission extends JsonApiModel {

  @Attribute()
  name: string;
}
