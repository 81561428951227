import {Component, Inject, OnInit} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Server} from '../../../../../models/server';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Datastore} from '../../../../../services/datastore';

@Component({
    selector: 'app-add-server-dialog',
    templateUrl: './add-server-dialog.component.html',
    styleUrls: ['./add-server-dialog.component.scss']
})
export class AddServerDialogComponent {
    public form: FormGroup;
    private loading: boolean;
    public addSubscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<AddServerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Server,
        private readonly formBuilder: FormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly datastore: Datastore,
    ) {
        this.form = this.formBuilder.group({
            provider: ['Hetzner', [Validators.required]],
            providerId: [null, [Validators.required]],
        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.loading = true;

        let server = this.datastore.createRecord(Server, this.form.value)

        server.save().subscribe((server) => {
            this.snackbar.open('Server added', 'Close');
            this.loading = false;
            this.dialogRef.close();

            this.router.navigateByUrl('/servers/' + server.id);
        });
    }

    public getPlaceholderFormProvider(): {placeholder: string, numeric: boolean} {
        switch (this.form.get('provider').value) {
            case 'Hetzner':
                return {placeholder: '#######', numeric: true};
            case 'Leaseweb':
                return {placeholder: '####', numeric: true};
            default:
                return {placeholder: '###-###-###', numeric: false};
        }
    }
}
