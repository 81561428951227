import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Server} from '../../../../../models/server';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Datastore} from '../../../../../services/datastore';
import {Router} from '@angular/router';

@Component({
  selector: 'app-remove-server-confirm-dialog',
  templateUrl: './remove-server-confirm-dialog.component.html',
  styleUrls: ['./remove-server-confirm-dialog.component.scss']
})
export class RemoveServerConfirmDialogComponent{

    public form: FormGroup;


    constructor(
        public dialogRef: MatDialogRef<RemoveServerConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Server,
        private readonly formBuilder: FormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly datastore: Datastore,
        private readonly router: Router,
    ) {
        this.form = this.formBuilder.group({
            product: ['', [Validators.required]]
        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.valid()) {
            return;
        }

        this.snackbar.open('The server will be removed shortly');

        this.router.navigate(['/servers']);
        this.dialogRef.close();
    }

    public valid(): boolean {
        return this.form.valid &&
            this.form.controls.product.value.toLocaleLowerCase() === this.data.productType.toLocaleLowerCase();
    }


}
