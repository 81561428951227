<div class="row">
    <div id="main-profile-container">
        <mat-card>
            <mat-card-header>
                <img [src]="authService.getCurrentUserAvatar()" alt="" mat-card-avatar class="profile-image">
                <mat-card-title>{{authService.getCurrentUser().name}}</mat-card-title>
                <mat-card-subtitle>{{authService.getCurrentUser().email}}</mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </div>
</div>
