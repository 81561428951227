import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-details-server-rescue-dialog',
    templateUrl: './details-server-rescue-dialog.component.html',
    styleUrls: ['./details-server-rescue-dialog.component.scss']
})
export class DetailsServerRescueDialogComponent{

    constructor(
        public dialogRef: MatDialogRef<DetailsServerRescueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Object,
    ) {

    }

    public onClose(): void {
        this.dialogRef.close();
    }
}
