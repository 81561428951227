import {
    Attribute,
    BelongsTo,
    HasMany,
    JsonApiDatastore,
    JsonApiModel,
    JsonApiModelConfig
} from 'mpugach-angular2-jsonapi';
import {Observable} from 'rxjs';
import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {Network} from './network';
import {Datacenter} from './datacenter';
import {Assignee} from './assignee';

class Feature {
    name: string;
    available: boolean;
    options: string[]|[key: string[]];
}

@JsonApiModelConfig({
  type: 'servers'
})
export class Server extends JsonApiModel {

    private meta: object|null;

    constructor(internalDatastore: JsonApiDatastore, data?: any) {
        super(internalDatastore, data);

        this.meta = data?.meta;
    }

    @Attribute()
    name: string;

    @Attribute()
    displayName: string;

    @Attribute()
    provider: string;

    @Attribute()
    providerId: string;

    @Attribute()
    providerName: string;

    @Attribute()
    providerPrice: number;

    @Attribute()
    providerPriceFrequency: string;

    @Attribute()
    whmcsId: string;

    @Attribute()
    whmcsUrl: string;

    @Attribute()
    whmcsPrice: number;

    @Attribute()
    whmcsPriceFrequency: string;

    @Attribute()
    productType: string;

    @Attribute()
    status: string;

    @Attribute()
    purpose: string;

    @Attribute()
    specs: any|null;

    @Attribute()
    contractExpireAt: Date|null;

    @Attribute()
    createdAt: Date;

    @Attribute()
    updatedAt: Date;

    @HasMany()
    networks: Network[];

    @BelongsTo()
    datacenter: Datacenter;

    @BelongsTo()
    assignee: Assignee;

    public getFeature(name: string): Feature
    {
        if (this.meta === undefined || this.meta['features'] == undefined) {
            return null;
        }

        return this.meta['features'].find(a => a['name'] === name) as Feature;
    }
}
