import {Attribute, JsonApiModel, JsonApiModelConfig} from 'mpugach-angular2-jsonapi';

@JsonApiModelConfig({
  type: 'assignees'
})
export class Assignee extends JsonApiModel {
    @Attribute()
    name: string;

    @Attribute()
    email: string;

    @Attribute()
    whmcsId: string;

    @Attribute()
    whmcsUrl: string;
}
