<h1 mat-dialog-title>Rescue Server</h1>
<div mat-dialog-content [formGroup]="form">
    <p>
        Are you sure you would like to restart the server into rescue? <br/>
        The rescue password will be provided after the confirmation.
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.form.valid">Restart</button>
</div>
