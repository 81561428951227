import {Attribute, HasMany, JsonApiModel, JsonApiModelConfig} from 'mpugach-angular2-jsonapi';
import { Role } from './role';

@JsonApiModelConfig({
    type: 'users'
})
export class User extends JsonApiModel {

    @Attribute()
    public name: string;

    @Attribute()
    public email: string;

    @Attribute()
    public createdAt: Date;

    @Attribute()
    public updatedAt: Date;
}

