<h1 mat-dialog-title>Edit Server</h1>
<div mat-dialog-content [formGroup]="form">
    <mat-form-field floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="Kasper's VLA, uhh.. Server">
        <mat-hint>
            Name might not be shown in some cases, like customer purposed servers.
        </mat-hint>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Purpose</mat-label>
        <mat-select formControlName="purpose">
            <mat-option value="customer">customer</mat-option>
            <mat-option value="appslot">appslot</mat-option>
            <mat-option value="seedbox">seedbox</mat-option>
            <mat-option value="miscellaneous">miscellaneous</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!this.importedSpecs">
        <p>
            <strong>Specifications</strong>
        </p>

        <ng-container formArrayName="specs">
            <ng-container formArrayName="cpu">
                <div style="display: flex;">
                    <mat-form-field style="width: 60px;" floatLabel="always">
                        <mat-label>#</mat-label>
                        <input matInput formControlName="quantity" type="number" min="0" max="99" maxlength="2" placeholder="1" />
                        <span matSuffix>x</span>
                    </mat-form-field>

                    <mat-form-field floatLabel="always">
                        <mat-label>CPU</mat-label>
                        <input matInput formControlName="name" placeholder="Intel Xeon E5-2620v4">
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container formArrayName="memory">
                <mat-form-field floatLabel="always">
                    <mat-label>Memory</mat-label>
                    <input matInput formControlName="size" type="number" min="0" max="9999" maxlength="4" placeholder="256" />
                    <span matSuffix>GB</span>
                </mat-form-field>
            </ng-container>

            <ng-container formArrayName="network">
                <div style="display: flex;">
                    <mat-form-field style="width: 60px;" floatLabel="always">
                        <mat-label>#</mat-label>
                        <input matInput formControlName="quantity" type="number" min="0" max="99" maxlength="2" placeholder="1" />
                        <span matSuffix>x</span>
                    </mat-form-field>

                    <mat-form-field floatLabel="always">
                        <mat-label>Network speed</mat-label>
                        <mat-select formControlName="speed">
                            <mat-option value="1g">1G</mat-option>
                            <mat-option value="10g">10G</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-container formArrayName="disks">
                <ng-container *ngFor="let diskForm of disks.controls; let diskId = index">
                    <div style="display: flex;" [formGroupName]="diskId">
                        <mat-form-field style="width: 60px;" floatLabel="always">
                            <mat-label>#</mat-label>
                            <input matInput formControlName="quantity" type="number" min="0" max="99" maxlength="2" placeholder="1" />
                            <span matSuffix>x</span>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" style="width: 80px;">
                            <mat-label>Size</mat-label>
                            <input matInput formControlName="size" type="number" min="0" max="99999"  maxlength="5" placeholder="1024" />
                        </mat-form-field>

                        <mat-form-field floatLabel="always" style="width: 60px;">
                            <mat-label>Unit</mat-label>
                            <mat-select formControlName="unit">
                                <mat-option value="gb">GB</mat-option>
                                <mat-option value="tb">TB</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" style="width: 80px;">
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="ssd">SSD</mat-option>
                                <mat-option value="nvme">NVMe</mat-option>
                                <mat-option value="hdd">HDD</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button mat-mini-fab color="primary" style="margin-top: 5px;" (click)="addDisk()" *ngIf="diskId === 0">
                            <mat-icon>add</mat-icon>
                        </button>

                        <button mat-mini-fab color="warn" style="margin-top: 5px;" (click)="removeDisk(diskId)" *ngIf="diskId !== 0">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.form.valid">Save</button>
</div>
