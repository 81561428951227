import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Server} from '../../../../models/server';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Datastore} from '../../../../services/datastore';

@Component({
    selector: 'app-day-select-dialog',
    templateUrl: './day-select-dialog.component.html',
    styleUrls: ['./day-select-dialog.component.scss']
})
export class DaySelectDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DaySelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ){
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        this.dialogRef.close(
            {
                from: this.data.from,
                to: this.data.to,
            }
        );
    }
}
