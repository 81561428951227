
<div id="box">
    <mat-card id="logo-container">
        <img class="mb-4" src="/assets/images/logo.png" alt="profile image">
    </mat-card>
    <mat-card id="auth-container">
        <router-outlet></router-outlet>
    </mat-card>

</div>
