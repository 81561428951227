<h1 mat-dialog-title>Rescue Server</h1>
<div mat-dialog-content>
    <p>
        The server is rebooting into rescue mode. <br/>
        You are able to use the following credentials to connect via SSH:
    </p>
    <ul>
        <li>
            Username: {{ data['username'] }}
        </li>
        <li>
            Password: {{ data['password'] }}
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onClose()" color="primary">Close</button>
</div>
