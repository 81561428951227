<h1 mat-dialog-title>Stop Server</h1>
<div mat-dialog-content [formGroup]="form">
    <p>
        Are you sure you would like to remove this server from inventory?
    </p>
    <p>
        <code class="code w-100">{{this.data.productType}}</code>
    </p>
    <p>
        To remove this server, please type the product name.
    </p>

    <mat-form-field>
        <mat-label>Product name</mat-label>
        <input type="text" matInput formControlName="product">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.valid()" [class.disabled]="!this.valid()">Remove</button>
</div>
