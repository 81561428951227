import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LayoutComponent} from './components/layout/layout.component';
import {MainComponent} from './components/main/main.component';
import {LoggedInGuard} from '../guards/logged-in.guard';
import {ProfileComponent} from './components/profile/profile.component';
import {ServersComponent} from './components/servers/servers.component';
import {ServerDetailComponent} from './components/server-detail/server-detail.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [
          LoggedInGuard
        ],
        children: [
            {
                path: 'dashboard',
                component: MainComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'servers',
                children: [
                    {
                        path: '',
                        component: ServersComponent
                    },
                    {
                        path: ':id',
                        component: ServerDetailComponent
                    }
                ]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
