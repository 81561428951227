import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {Datastore} from './datastore';
import {catchError, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {Md5} from 'ts-md5';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authUrl = '/api/v1/users/auth';
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private defaultIncludes = '';

    constructor(
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
        private readonly router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(null);
        this.currentUser = this.currentUserSubject.asObservable();
        this.forceFetchCurrentUser().subscribe();
    }

    public login(form: {email: string, password: string, remember?: boolean}): Observable<User> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.api+json');
        const loginUser = this.datastore.createRecord(User);
        loginUser.email = form.email;
        loginUser.password = form.password;
        loginUser.remember = form.remember;

        return loginUser.save({
            include: this.defaultIncludes
        }, headers, `${this.authUrl}/login`).pipe(
            tap((user: User) => {
                this.setCurrentUser(user);
            })
        );
    }

    /**
     * Set current user
     *
     * @param user: User|null
     * @returns void
     */
    protected setCurrentUser(user: User = null): void {
        this.currentUserSubject.next(user);
    }

    /**
     * Empty current user and get the new user data.
     *
     * @returns Observable<boolean>
     */
    public forceFetchCurrentUser(): Observable<boolean> {
        this.currentUserSubject.next(null);
        return this.isLoggedIn();
    }

    /**
     * Method to check and/or retrieve the user if it is logged in.
     *
     * @returns Observable<boolean>
     */
    public isLoggedIn(): Observable<boolean> {
        if (this.currentUserSubject.value instanceof User) {
            return of(true);
        }

        return this.datastore.findRecord(
            User,
            null,
            {
                include: this.defaultIncludes
            },
            null,
            `${this.authUrl}/current`
        ).pipe(
            map((user: User) => {
                this.setCurrentUser(user);
                return true;
            }),
            catchError((error) => {
                this.currentUserSubject.next(null);
                return of(false);
            })
        );
    }

    /**
     * Logout the current User.
     */
    public logout(): Observable<string | boolean | object> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.api+json');
        return this.http.get(`${this.authUrl}/logout`, {headers})
            .pipe(
                tap(() => {
                    this.setCurrentUser(null);
                    this.navigateToLogin();
                }),
                catchError(() => {
                    // Force reload
                    document.location.href = '/';
                    return of('loggingout');
                })
            );
    }

    public navigateToLogin(): void {
        this.router.navigate(['/'], {queryParams: {redirect: this.router.url}});
    }

    /**
     * Get current user from subject.
     *
     * @returns User
     */
    public getCurrentUser(): User {
        return this.currentUserSubject.getValue();
    }

    /**
     * Get current user's profile picture.
     *
     * @returns string
     */
    public getCurrentUserAvatar(): string {
        return 'https://www.gravatar.com/avatar/' + Md5.hashStr(this.getCurrentUser().email) + '?d=retro';
    }
}
