import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LogoutConfirmDialogComponent} from '../toolbar/logout-confirm-dialog/logout-confirm-dialog.component';
import {AuthService} from '../../../services/auth.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {LoaderService} from '../../../services/loader.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

    constructor(
        private readonly dialog: MatDialog,
        public readonly authService: AuthService,
        private readonly loaderService: LoaderService,
    ) { }

    public static displayThreshold = 750;
    public resizeObservable$: Observable<Event>;
    public menuIsOpen = false;
    @ViewChild('sidenav') sidenav: MatSidenav;

    public ngOnInit(): void {
        this.menuIsOpen = window.innerWidth > LayoutComponent.displayThreshold;

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeObservable$.subscribe( (evt: Event) => {
            // @ts-ignore
            this.menuIsOpen = evt.currentTarget.innerWidth > LayoutComponent.displayThreshold;
            this.sidenav.mode = this.menuIsOpen ? 'side' : 'over';
        });

        this.loaderService.show();
    }

    public ngAfterViewInit(): void {
        this.loaderService.hide();
    }

    public logoutHandle(): void {
        this.dialog.open(LogoutConfirmDialogComponent, {
            width: '250px'
        });
    }
}
