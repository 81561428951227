import { Pipe, PipeTransform } from '@angular/core';
import {isString} from 'lodash-es';

@Pipe({
    name: 'serverType'
})
export class ServerTypePipe implements PipeTransform {

    public transform(value: string|null, ...args: unknown[]): string {
        if (!isString(value)){
            return '';
        }
        return value.split('\\').join('-');
    }

}
