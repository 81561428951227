import {Attribute, HasMany, JsonApiModel, JsonApiModelConfig} from 'mpugach-angular2-jsonapi';
import {Permission} from './permission';

@JsonApiModelConfig({
  type: 'roles'
})
export class Role extends JsonApiModel {
  @Attribute()
  name: string;

  @HasMany()
  permissions: Permission[];
}
