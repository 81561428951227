import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ExceptionHandler} from './core/exceptions/exception-handler';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {VersionMismatchInterceptor} from './core/interceptors/version-mismatch.interceptor';
import {NoConnectionInterceptor} from './core/interceptors/no-connection.interceptor';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {AuthModule} from './auth/auth.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {DashboardModule} from './dashboard/dashboard.module';
import { LoaderComponent } from './components/loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AuthModule,
        MatSnackBarModule,
        DashboardModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: NoConnectionInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: VersionMismatchInterceptor, multi: true},
        {provide: ErrorHandler, useClass: ExceptionHandler},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}}
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
