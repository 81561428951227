<h1 mat-dialog-title>Re-install Server</h1>
<div mat-dialog-content [formGroup]="form">
    <p>
        Are you sure you would like to re-install this server?
    </p>
    <mat-form-field>
        <mat-label>Operating system</mat-label>
        <mat-select formControlName="image">
            <mat-option *ngFor="let image of (this.server.getFeature('reinstall').options['images'] | keyvalue)" [value]="image.key">{{image.value}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.form.valid">Re-install</button>
</div>
