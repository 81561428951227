<h1 mat-dialog-title>Stop Server</h1>
<div mat-dialog-content [formGroup]="form">
    <p>
        Are you sure you would like to stop this server?
    </p>
    <p>
        <code class="code w-100">{{this.server.productType}}</code>
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.form.valid">Stop</button>
</div>
