import { Component } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  constructor(
      private readonly authService: AuthService,
      private readonly router: Router
  ) {
      this.authService.isLoggedIn().subscribe((loggedin: boolean) => {
          if (loggedin) {
              this.router.navigate(['dashboard']);
          }
      });
  }
}
