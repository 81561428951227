import {DatastoreConfig, JsonApiDatastore, JsonApiDatastoreConfig} from 'mpugach-angular2-jsonapi';
import {Injectable} from '@angular/core';
import {JsonApiErrorResponse} from './json-api-error-response';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {Permission} from '../models/permission';
import {Role} from '../models/role';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Server} from '../models/server';
import {Datacenter} from '../models/datacenter';
import {Network} from '../models/network';
import {Assignee} from '../models/assignee';

const config: DatastoreConfig = {
    baseUrl: environment.apiBaseUrl,
    models: {
        users: User,
        permissions: Permission,
        roles: Role,
        servers: Server,
        datacenters: Datacenter,
        networks: Network,
        assignees: Assignee,
    }
};

@Injectable({
    providedIn: 'root'
})
@JsonApiDatastoreConfig(config)
export class Datastore extends JsonApiDatastore {
    constructor(
        protected readonly http: HttpClient,
        protected readonly snackbar: MatSnackBar
    ) {
        super(http);
    }

    public showError(errorResponse: JsonApiErrorResponse): void {
        let errorShown = false;
        let detail = '';
        const errorTitle = errorResponse && errorResponse.errors && errorResponse.errors.length > 0 ? errorResponse.errors[0].title : null;

        const title = errorTitle && errorTitle !== 'Internal Server Error' && errorTitle !== 'Unprocessable Entity' ?
            errorTitle : 'Failed to process your request!';

        // Json-Api error handling
        if (
            errorResponse.errors &&
            errorResponse.errors instanceof Array
        ) {
            errorResponse.errors.forEach((error) => {
                errorShown = true;
                detail += error.detail
                    ? error.detail
                    : 'Unprocessable error';
            });
        }

        // Non json-api error but laravel validation error
        if (detail === '' && errorResponse.error && errorResponse.error.message) {
            detail = errorResponse.error.message;
        }

        // Non json-api error but plain server error
        if (detail === '' && errorResponse.message) {
            detail = errorResponse.message;
        }

        this.snackbar.open(detail);
    }
}
