import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../services/auth.service';

class DialogData {
}

@Component({
  selector: 'app-logout-confirm-dialog',
  templateUrl: './logout-confirm-dialog.component.html',
  styleUrls: ['./logout-confirm-dialog.component.scss']
})
export class LogoutConfirmDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<LogoutConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private readonly authService: AuthService,
    ) {}

    public onNoClick(): void {
        this.dialogRef.close(false);
    }

    public logout(): void {
        this.dialogRef.close(true);
        this.authService.logout().subscribe();
    }
}
