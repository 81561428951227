<div class="row">
    <div class="col-md-4">
        <mat-card class="mt-4">
            <mat-card-title>
                <div class="row">
                    <div class="col-10">
                        {{this.server?.displayName.length > 0 ? this.server?.displayName : 'No name'}}
                    </div>
                    <div class="col-2">
                        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="showEditServer()">Edit</button>
                        </mat-menu>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-subtitle>{{(this.server?.productType) ?? '-'}} at {{this.server?.provider | serverType}}</mat-card-subtitle>
            <mat-card-content>
                <div class="row" *ngIf="this.server?.name">
                    <div class="col-4"><strong>Name</strong></div>
                    <div class="col-8">{{(this.server?.name)}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Purpose</strong></div>
                    <div class="col-8">{{(this.server?.purpose) ?? '-'}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Status</strong></div>
                    <div class="col-8">{{this.server?.status|titlecase}}</div>
                </div>

                <div class="row">
                    <div class="col-4"><strong>Created at</strong></div>
                    <div class="col-8">{{this.server?.createdAt | date:'medium'}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Updated at</strong></div>
                    <div class="col-8">{{this.server?.updatedAt | date:'medium'}}</div>
                </div>

                <div class="row" style="margin-top: 10px;">
                    <div class="col-4"><strong>Product</strong></div>
                    <div class="col-8">{{(this.server?.productType) ?? '-'}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Datacenter</strong></div>
                    <div class="col-8">{{(this.server?.datacenter?.name) ?? '-'}}</div>
                </div>

                <ng-container *ngIf="this.server?.specs">
                    <div class="row">
                        <div class="col-4"><strong>CPU</strong></div>
                        <div class="col-8">{{ this.server?.specs?.cpu?.quantity }}x {{ this.server?.specs?.cpu?.name }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4"><strong>Memory</strong></div>
                        <div class="col-8">{{ this.server?.specs?.memory?.size }} {{ this.server?.specs?.memory?.unit | uppercase }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4"><strong>Network</strong></div>
                        <div class="col-8">{{ this.server?.specs?.network?.quantity }}x {{ this.server?.specs?.network?.speed }}</div>
                    </div>
                    <div class="row">
                        <div class="col-4"><strong>Disks</strong></div>
                        <div class="col-8">
                            <ng-container *ngFor="let disk of this.server?.specs?.disks; let isLast = last">
                                {{ disk.quantity }}x {{ disk.type | uppercase }} {{ disk.size }} {{ disk.unit | uppercase }}{{ isLast ? '' : ', ' }}
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button color="primary" (click)="showRescueConfirm()" *ngIf="this.server?.getFeature('rescue')?.available">Rescue</button>
                <button mat-button color="primary" (click)="showRestartConfirm()" *ngIf="this.server?.getFeature('restart')?.available">Restart</button>
                <button mat-button color="primary" (click)="showStartConfirm()" *ngIf="this.server?.getFeature('start')?.available">Start</button>
                <button mat-button color="accent" (click)="showStopConfirm()" *ngIf="this.server?.getFeature('stop')?.available">Stop</button>
                <button mat-button color="warn" (click)="showPauseConfirm()" *ngIf="this.server?.getFeature('pause')?.available">Suspend</button>
                <button mat-button color="warn" (click)="showUnpauseConfirm()" *ngIf="this.server?.getFeature('unpause')?.available">Unsuspend</button>
                <button mat-button color="primary" (click)="ShowReinstallConfirm()" *ngIf="this.server?.getFeature('reinstall')?.available">Re-install</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="mt-4">
            <mat-card-title>Provider</mat-card-title>
            <mat-card-subtitle>{{this.server?.provider | serverType}}</mat-card-subtitle>
            <mat-card-content>
                <div class="row">
                    <div class="col-4"><strong>ID at {{this.server?.provider | serverType}}</strong></div>
                    <div class="col-8">{{this.server?.providerId}}</div>
                </div>
                <div class="row" *ngIf="this.server?.providerName">
                    <div class="col-4"><strong>Name</strong></div>
                    <div class="col-8">{{this.server?.providerName}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Provider</strong></div>
                    <div class="col-8">{{this.server?.provider | serverType}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Contract expiry</strong></div>
                    <div class="col-8">{{(this.server?.contractExpireAt | date:'mediumDate') ?? '-'}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Price</strong></div>
                    <div class="col-8">{{(this.server?.providerPrice | currency:'EUR') ?? '-' }} per {{getReadablePriceFrequency(this.server?.providerPriceFrequency)}}</div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="mt-4">
            <mat-card-title>WHMCS</mat-card-title>
            <mat-card-subtitle *ngIf="this.server?.assignee">Assigned to {{this.server?.assignee?.name}}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="!this.server?.assignee">Unassigned</mat-card-subtitle>
            <mat-card-content *ngIf="!this.server?.assignee">
                <span>This server is currently not assigned to any service in WHMCS.</span>
            </mat-card-content>
            <mat-card-content *ngIf="this.server?.assignee">
                <div class="row">
                    <div class="col-4"><strong>Service ID</strong></div>
                    <div class="col-8">{{this.server?.whmcsId}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Client ID</strong></div>
                    <div class="col-8">{{this.server?.assignee?.whmcsId}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Name</strong></div>
                    <div class="col-8">{{this.server?.assignee?.name}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Email</strong></div>
                    <div class="col-8">{{this.server?.assignee?.email}}</div>
                </div>
                <div class="row">
                    <div class="col-4"><strong>Price</strong></div>
                    <div class="col-8">{{(this.server?.whmcsPrice | currency:'EUR') ?? '-' }} per {{getReadablePriceFrequency(this.server?.whmcsPriceFrequency)}}</div>
                </div>
            </mat-card-content>
            <mat-card-actions *ngIf="this.server?.assignee">
                <button mat-button color="primary" (click)="openWhmcs(this.server?.whmcsUrl)" *ngIf="this.server?.whmcsUrl">Open service in WHMCS</button>
                <button mat-button color="primary" (click)="openWhmcs(this.server?.assignee?.whmcsUrl)" *ngIf="this.server?.assignee?.whmcsUrl">Open client in WHMCS</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="col-md-8 mt-md-0">
        <mat-card *ngIf="this.server?.getFeature('traffic-statistics')?.available" class="mt-4">
            <mat-card-title>
                <div class="row">
                    <div class="col-11">
                        Traffic usage
                    </div>
                    <div class="col-1">
                        <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="setTrafficStatsDuration('today')">Last 24 hours</button>
                            <button mat-menu-item (click)="setTrafficStatsDuration('week')">Last 7 days</button>
                            <button mat-menu-item (click)="setTrafficStatsDuration('month')">Last 30 days</button>
                            <button mat-menu-item (click)="setTrafficStatsDuration('year');">Last 1 year</button>

                            <button mat-menu-item (click)="setTrafficStatsPeriod('today')">Today</button>
                            <button mat-menu-item (click)="setTrafficStatsPeriod('yesterday')">Yesterday</button>
                            <button mat-menu-item (click)="setTrafficStatsPeriod('month')">This Month</button>
                            <button mat-menu-item (click)="setTrafficStatsPeriod('year');">This Year</button>

                            <button mat-menu-item (click)="customTrafficStatsPeriod()">Custom</button>
                        </mat-menu>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <canvas #performanceChart height="100"></canvas>
            </mat-card-content>
        </mat-card>
        <mat-card class="mt-4">
            <mat-card-title>Network</mat-card-title>
            <mat-card-content>
                <table mat-table [dataSource]="server?.networks" class="w-100" aria-describedby="Networks">
                    <caption>Network</caption>
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef> Address </th>
                        <td mat-cell width="35%" *matCellDef="let element">{{element.address}}{{element.mask ? '/' + element.mask : ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.networkType | titlecase}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pointer"></tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
