import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user';
import {JsonApiErrorResponse} from '../../../services/json-api-error-response';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Datastore} from '../../../services/datastore';
import {Router} from '@angular/router';
import {LoaderService} from '../../../services/loader.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    public form: FormGroup;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly snackbar: MatSnackBar,
        private readonly datastore: Datastore,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
    ) {
        this.form = this.formBuilder.group(
            {
                email : ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required]],
                remember: []
            }
        );
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }


        this.authService.login(this.form.value).subscribe(
            (user: User) => {
                this.router.navigateByUrl('/dashboard');
            },
            (error: JsonApiErrorResponse) => {
                this.datastore.showError(error);
            }
        );
    }

    public login(): void {
        this.loaderService.show();
        window.location.href = '/api/login/whmcs/redirect'
    }

    public getCurrentYear(): number {
        return new Date().getFullYear();
    }
}
