<h1 mat-dialog-title>Add Server</h1>
<div mat-dialog-content [formGroup]="form">
    <mat-icon id="loader" *ngIf="this.addSubscription?.closed === false">youtube_searched_for</mat-icon>
    <div id="loader-text"  *ngIf="this.addSubscription?.closed === false">Loading...</div>
    <form [formGroup]="this.form" *ngIf="this.addSubscription?.closed === null">
        <p>
            Manually adding a server will make sure the server is added right away into SIMP. By default new servers are
            added every two hours automatically.
        </p>
        <mat-radio-group formControlName="provider" required>
            <div class="row">
                <label>Provider</label>
            </div>
            <div class="row p-1">
                <div class="col-3">
                    <mat-radio-button [value]="'Hetzner'">
                        <img src="/assets/images/providers/hetzner.png" class="w-100" alt="Hetzner logo">
                    </mat-radio-button>
                </div>
                <div class="col-3">
                    <mat-radio-button [value]="'Leaseweb'">
                        <img src="/assets/images/providers/leaseweb.png" class="w-100" alt="Leaseweb logo">
                    </mat-radio-button>
                </div>
            </div>
        </mat-radio-group>
        <mat-form-field class="mt-2">
            <mat-label>Identifier</mat-label>
            <input matInput
                   [placeholder]="this.getPlaceholderFormProvider().placeholder"
                   [type]="this.getPlaceholderFormProvider().numeric ? 'number' : 'text'"
                   formControlName="providerId"
                   required>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="actions" *ngIf="this.addSubscription?.closed === null">
    <button mat-raised-button color="accent" (click)="this.onNoClick()"><mat-icon>close</mat-icon> Cancel</button>
    <button mat-raised-button color="primary" (click)="this.submit()"><mat-icon>add</mat-icon> Add</button>
</div>
