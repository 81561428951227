<h1 mat-dialog-title>Restart Server</h1>
<div mat-dialog-content [formGroup]="form">
    <p>
        Are you sure you would like to restart this server?<br/>
        Please select a reboot option and type the product name.
    </p>
    <mat-form-field>
        <mat-label>Reboot method</mat-label>
        <mat-select formControlName="rebootOption" required>
            <mat-option *ngFor="let rebootMethod of this.getRestartOptions() | keyvalue" [value]="rebootMethod.key">
                {{rebootMethod.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary" [disabled]="!this.form.valid">Restart</button>
</div>
