import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector
} from '@angular/core';
import {LoaderComponent} from '../components/loader/loader.component';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private ref: ComponentRef<unknown> = null;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
    ) {}

    public show(): void {
        if (!this.ref) {
            this.ref = this.appendComponentToBody(LoaderComponent);
        }
    }

    public hide(): void {
        this.removeComponentFromBody(this.ref);
        this.ref = null
    }

    private appendComponentToBody(component: any) {
        //create a component reference
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(component)
            .create(this.injector);

        // attach component to the appRef so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView);

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef < any > )
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        return componentRef;
    }

    private removeComponentFromBody(componentRef: ComponentRef < any > ) {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }
}
