import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Server} from '../../../../../models/server';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {JsonApiErrorResponse} from '../../../../../services/json-api-error-response';
import {Datastore} from '../../../../../services/datastore';
import {
    DetailsServerRescueDialogComponent
} from "../details-server-rescue-dialog/details-server-rescue-dialog.component";

@Component({
    selector: 'app-confirm-server-rescue-dialog',
    templateUrl: './confirm-server-rescue-dialog.component.html',
    styleUrls: ['./confirm-server-rescue-dialog.component.scss']
})
export class ConfirmServerRescueDialogComponent{
    public form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ConfirmServerRescueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public server: Server,
        private readonly formBuilder: FormBuilder,
        private readonly snackbar: MatSnackBar,
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
        private readonly dialog: MatDialog,
    ) {

        this.form = this.formBuilder.group({

        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.http.patch('/api/v1/servers/' + this.server.id + '/rescue', {}, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
            }
        }).subscribe((data) => {
            this.snackbar.open('The server will be restarted into rescue shortly');
            this.dialogRef.close();

            this.dialog.open(DetailsServerRescueDialogComponent, {data: data});
        }, (error: JsonApiErrorResponse) => this.datastore.showError(error));
    }

    public getRestartOptions(): string[] {
        return this.server.getFeature('restart').options as string[];
    }
}
